import * as React from "react";
import { Helmet } from "react-helmet";
import { graphql, useStaticQuery } from "gatsby";
/* import Data from "../../data/data.js"; */

import "../../scss/main.scss";

import Navbar from "../../components/Navbar-en";
import CardBox from "../../components/CardBox";
import HeroHome from "../../components/HeroHome";
import SideTabs from "../../components/SideTabs";
import LogosGallery from "../../components/LogosGallery";
import Contact from "../../components/Contact";
import Footer from "../../components/Footer-en";
import WhatsappButton from "../../components/WhatsappButton";

import { slugify } from "../../utils/helpers";

import 'bootstrap/dist/css/bootstrap.min.css';

// markup
const IndexPage = () => {
  /* const domain = ""; */

  const data = useStaticQuery(graphql`
    query {
      allContentfulService(sort: { fields: title_en, order: ASC }) {
        nodes {
          title_en
        }
      }
      allContentfulProduct(sort: { fields: title_en, order: ASC }) {
        nodes {
          title_en
        }
      }
      allContentfulSolution(sort: { fields: title_en, order: ASC }) {
        nodes {
          title_en
        }
      }
      allContentfulHomePageTemplate {
        nodes {
          hero_title_en
          childContentfulHomePageTemplateHeroDescriptionEnTextNode {
            hero_description_en
          }
          hero_image {
            file {
              url
            }
          }

          childContentfulHomePageTemplateSectionServiciosDescriptionEnTextNode {
            section_servicios_description_en
          }
          childContentfulHomePageTemplateSectionProductsDescriptionEnTextNode {
            section_products_description_en
          }
          childContentfulHomePageTemplateSectionSolutionsDescriptionEnTextNode {
            section_solutions_description_en
          }
        }
      }
    }
  `);

  const heroTitle = data.allContentfulHomePageTemplate.nodes[0].hero_title_en;
  const heroDescription =
    data.allContentfulHomePageTemplate.nodes[0].childContentfulHomePageTemplateHeroDescriptionEnTextNode
      .hero_description_en;
  const heroImage = data.allContentfulHomePageTemplate.nodes[0].hero_image.file.url;
  const pageTitle = "Virtual Ed Global | " + heroTitle;

  const servicesDescription =
    data.allContentfulHomePageTemplate.nodes[0].childContentfulHomePageTemplateSectionServiciosDescriptionEnTextNode
      .section_servicios_description_en;
  const productsDescription =
    data.allContentfulHomePageTemplate.nodes[0].childContentfulHomePageTemplateSectionProductsDescriptionEnTextNode
      .section_products_description_en;
  const solutionsDescription =
    data.allContentfulHomePageTemplate.nodes[0].childContentfulHomePageTemplateSectionSolutionsDescriptionEnTextNode
      .section_solutions_description_en;

  return (
    <main>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{pageTitle}</title>
        <meta name="description" content={heroDescription} />
        {/* <link rel="icon" type="image/png" href="" /> */}
        <meta property="og:title" content={pageTitle} />
        <meta property="og:site_name" content="Virtual Ed Global" />
        <meta property="og:url" content="https://virtualedglobal.com/en/" />
        <meta property="og:description" content={heroDescription} />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={heroImage} />
        <meta name="twitter:image" content={heroImage} />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="twitter:description" content={heroDescription} />        
      </Helmet>

      <Navbar />

      <HeroHome
        title={heroTitle}
        description={heroDescription}
        image={heroImage}
        buttonLabel="Contact"
        link="contact"
      />

      <div className="page-wrapper">
        <section>
          <div className="container">
            <h2 className="title">Why Virtual Ed Global?</h2>
            <SideTabs lang="en" />
          </div>
        </section>

        {/* Servicios */}
        <section>
          <div className="title-container">
            <div className="container">
              <div className="d-flex align-items-start tsb-wrapper">
                <h2 className="title-border">Services</h2>
                <p className="subtitle-border">{servicesDescription}</p>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              {data.allContentfulService.nodes.map((item) => {
                return (
                  <div className="col-12 col-md-6 cardbox-container" key={item.title_en}>
                    <CardBox title={item.title_en} link={"/en/services#" + slugify(item.title_en)} />
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        {/* Productos */}
        <section>
          <div className="title-container">
            <div className="container">
              <div className="d-flex align-items-start tsb-wrapper">
                <h2 className="title-border">Products</h2>
                <p className="subtitle-border">{productsDescription}</p>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              {data.allContentfulProduct.nodes.map((item) => {
                return (
                  <div className="col-12 col-md-4 cardbox-container" key={item.title_en}>
                    <CardBox title={item.title_en} link={"/en/products#" + slugify(item.title_en)} color="secondary" />
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        {/*  Soluciones  */}
        <section>
          <div className="title-container">
            <div className="container">
              <div className="d-flex align-items-start tsb-wrapper">
                <h2 className="title-border">Solutions</h2>
                <p className="subtitle-border">{solutionsDescription}</p>
              </div>
            </div>
          </div>

          <div className="container">
            <div className="row">
              {data.allContentfulSolution.nodes.map((item) => {
                return (
                  <div className="col-12 col-md-6 cardbox-container" key={item.title_en}>
                    <CardBox title={item.title_en} link={"/en/solutions#" + slugify(item.title_en)} />
                  </div>
                );
              })}
            </div>
          </div>
        </section>

        {/* Partners */}
        <section>
          <LogosGallery title="Together with our technology partners, we adapt top solutions to your needs and challenges" />
        </section>

        {/* Contact */}
        <section id="contact">
          <Contact
            title={
              <p>
                <strong>We are here to talk about Higher Education.</strong> Share your comments with us.
              </p>
            }
            lang="en"
            button_label="Contact"
          />
        </section>
      </div>
      <WhatsappButton lang="en" />
      <Footer  />
    </main>
  );
};

export default IndexPage;
